import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import {Solution} from '../../components/views';

const IndexPage = () => (
  <Layout>
    <SEO title="Residential" />
    <Solution
      name="commercial"
      navigationBarProps={{
        navigationTopBarIsInitiallyPartiallyTransparent: true,
      }}
    />
  </Layout>
);

export default IndexPage;
